@font-face {
    font-family: "Proxima Nova";
    /* src: local("Proxima Nova"),
     url("../src/assets/fonts/Proxima-Font-Family/proximanova-black.otf") format("typeface"),; */
    src: url('../../assets/fonts/Proxima-Font-Family/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('ttf'),
      url('../../assets/fonts/Proxima-Font-Family/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.current_card{
    background-color: white;
    margin-top: 20;
    left: 0;
}  
.current_total_border {
    padding-top: 20px;
    text-align: center;
    border-right: 3px solid #757575;
}

.current_total {
    padding-top: 20px;
    text-align: center;
}

.total_heading {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #757575;
}

.total_paragraph {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    letter-spacing: -0.02em;
    /* color: #1A1A1A; */
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 1em;
}

.wrapperPrevious {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 1em;
}

.activerow{}
.cohortRow{}
.gradRow{}
.mobile{
    font-family: 'Proxima Nova';
    font-weight: bold;
    left: 0;
    
    
}
/* .main {
    padding-top:90px;  
} */

.dropoutCard{
    
}

.mobilecard{
    padding-top: 10px;
}
.cb{
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .wrapperPrevious {
        grid-template-columns: repeat(1, 1fr);
    }
    
}

@media only screen and (max-width: 450px) {
    .cb{
        /* padding: -20px; */
        margin:0px
    }
    .mobilecard{
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        margin:20px 0px 20px 0px;
        /* background-color: #a83636; */
    }
    .dropoutCard{
        margin:0px 20px;
    }
    /* .main {
        padding-top:90px;  
    } */
    
    .total_heading {
        font-size: 16px;
        line-height: 10px;
        letter-spacing: -0.01em;
        padding-top:20px;
    }


    .total_paragraph {
        font-size: 22px;
        line-height: 20px;
        letter-spacing: -0.01em;
        padding-bottom: 20px;
    }

    .wrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .current_total_border {
        border-right: none;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
        /* border-left: 3px solid #757575; */
        padding: 1em 0.5em 0em 0.5em;
        background-color: white;
    }
    .current_card{
        background-color: transparent;
        border-radius: 0px;
        box-shadow: 0 0px 0px rgba(230, 227, 227, 0.5);
        /* padding: 0px; */
    }
    .current_card:hover{
        box-shadow: none;
    }
    .current_total {
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
        padding: 1em 0.5em 0.5em 0.5em;
        background-color: white;
    }
    .activerow{
        margin-left: 10px;
        margin-right: 10px;
    }
    .cohortRow{
        margin-left: 10px;
        margin-right: 10px;
    }
    .gradRow{
        margin-left: 10px;
        margin-right: 10px;
    }
    .mobile{
        margin-left: 20px;
        margin-right: 10px;
    }
    

}

.chart_paragraph {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #757575;
}

.chart_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0em;
    row-gap: 1em;
    text-align: center;
    margin-left: -30px;
}


.badgemarket {
    background-color: #757575;
    width: 80px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #7366FF;
    background-color: #F1F0FF;
    border: 0.8px solid #7366FF;
}
.cardbodyStyle{
    padding:4px 20px 4px 20px; 
}

.badgeProbation {
    background-color: #757575;
    width: 80px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #EB5757;
    background-color: 
    #FDEEEE;
    border: 0.8px solid #EB5757;
}


.badgeTrack {
    background-color: #757575;
    width: 80px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #50943C;
    background-color: 
    #E4FBDD;
    border: 0.8px solid #50943C;
}

.cardHeaderstyle{
  padding-top: 10px;
  padding-bottom: 10px;
}

.chart1_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;
    row-gap: 1em;
    align-items: center;
}

@media only screen and (max-width: 900px){
    .chart1_wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    .chart_wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}


.dropout_card{
    background-color: white;
    margin-top: 0px;
    margin-bottom: 0px;
}  

.dropout {
    /* padding-top: 160px; */
    text-align: center;
    /* border-right: 3px solid #757575; */
    /* margin-top: 20px; */
    justify-content: center;
    align-items: center;
    /* height: 450px; */
    /* padding-right: 10px; */
    /* padding-left: 0px; */
}
.dropout_border {
    padding-top: 20px;
    text-align: center;
    /* border-right: 3px solid #757575; */
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 0px;
}
.dropout1 {
    padding-top: 20px;
    text-align: center;
    /* border-right: 3px solid #757575;s */
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 0px;
}


@media only screen and (max-width: 990px) {
    .dropout{
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
        padding: 1em 0.5em 0em 0.5em;
        background-color: white;
        /* color: black; */
        /* height: 100px; */
    }
    .dropout1{
        border-right: none;
        border-radius: 15px;
        box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
        padding: 1em 0.5em 0em 0.5em;
        background-color: white;
        /* color: black; */
        margin:30px 10px 10px;

    }
    .dropout_border{
        border-right: none;
        border-radius: 15px;
        box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
        padding: 1em 0.5em 0em 0.5em;
        background-color: white;
        margin:10px 10px 0px 10px;

    }
    .dropout_card{
        background-color: transparent;
        border-radius: 0px;
        box-shadow: none;
        margin:-30px -10px 0px -10px;
        /* padding: 0px; */
        /* margin:0px 10px; */
       
    }
    .dropout_card:hover{
        box-shadow: none;
    }
    
}

.wrapperDropout {
    display: grid; 
    grid-template-columns:  repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 1em;
    padding: 10px;
}


.no_studentcard{
    padding: 0px 0px 10px 10px;
    font-size: 16px;
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial;

}


.navpublic {
    position: fixed;
    width: 100%;
    top: 0;
    border-radius: 0;
    box-shadow: 0 2px 8px rgba(230, 227, 227, 0.5);
    z-index: 1; 
    left: 0;
  } 


.public_wrapper {
    min-height: calc(100vh - 80px);
    margin-top:100px;
    margin-left: 0px; 
    margin-right: 0px;
    padding: 0px;
}

.public{
    margin-left: 0px;
    padding: 0px;
    margin-right: 0px;
}

@media only screen and (min-width: 550px) {
    .public{
        margin-left: 20px;
        margin-right: 10px;
    }
}
 
.switchLabel{
   display:inline-block;
   margin-right: 10px;
}